import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const plansMap = {
  1: '593a3ebe-0389-4b92-939f-cecae670ecb5',
  2: '657e0db5-a16c-4c0d-bd00-eb524b79b900',
  3: '0a021e07-96ef-4b6d-ab5e-de87c77f1e55',
}

const UpgradeViaCard = ({ isLoggedIn, plan, user }) => {
  useEffect(() => {
    if (window && window.createLemonSqueezy) {
      window.createLemonSqueezy()
    }
  }, [])

  const params = new URLSearchParams()
  params.set('embed', 1)
  params.set('media', 0)
  params.set('discount', 0)
  params.set('logo', 0)
  params.set('desc', 0)

  if (user && user.email) {
    params.set('checkout[email]', user.email)
    params.set('checkout[name]', user.username)
  }

  return (
    <div className="my-2">
      {isLoggedIn ? (
        <a
          href={`https://cboxera.lemonsqueezy.com/buy/${
            plansMap[plan]
          }?${params.toString()}`}
          className="lemonsqueezy-button btn btn-danger"
        >
          <i className="far fa-credit-card mr-2" />
          Pay with Card
        </a>
      ) : (
        <></>
      )}
    </div>
  )
}

UpgradeViaCard.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  // handleClick: PropTypes.func.isRequired,
  plan: PropTypes.number.isRequired,
}

export default UpgradeViaCard
